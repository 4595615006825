import type { Session } from "next-auth"
import Footer from "./footer";
import type { AppProps } from 'next/app'
import NavBar from "./navbar";
import { ReactNode } from "react";


const Layout = ({ children, hideFooter, title }: { children: ReactNode, hideFooter?: boolean, title?: string }) => {
    return (
        <div>
            <NavBar />
            <div className="py-5">
                <header>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">{title}</h1>
                    </div>
                </header>
                <main>
                    {/* <div className="mx-auto max-w-7xl sm:px-6 lg:px-8"> */}
                    {children}
                    {/* </div> */}
                </main>
            </div>
            <div>
            </div>
            {!hideFooter && <Footer />}
        </div>);
}

export default Layout;