import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
import { useSession, signIn, signOut } from "next-auth/react"
import type { Session } from "next-auth"
import { Fragment, useEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import UserAvatar from '../userAvatar'
import ScriptifyLogo from '../scriptifyLogo'
import { Notifications } from '@prisma/client'


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface NavBarProps {
  session: Session,
}

function getEnvColor() {
  if (process.env.SCRIPTIFY_ENV === "production") {
    return "bg-white"
  } else if (process.env.SCRIPTIFY_ENV === "staging") {
    return "bg-gray-200"
  } else {
    return "bg-white"
  }
}

export default function NavBar() {
  const router = useRouter();
  const navigation = [
    { name: 'mission', href: '/', current: router.pathname == '/' },
    // { name: 'Team', href: '/team', current: router.pathname == '/team' },
    { name: 'waitlist', href: '/waitlist', current: router.pathname == '/waitlist' },
    // { name: 'Research', href: '/research', current: router.pathname == '/research' },
    // { name: 'Investor Relations', href: '/investor-relations', current: router.pathname == '/investor-relations' },
    // { name: 'Contact', href: '/contact', current: router.pathname == '/contact' },
  ]

  const initialMenuOptions = [
    { title: 'Settings', href: '/settings' },
    { title: 'Sign out', href: '/sign-out' }
  ]

  const [menuOptions, setMenuOptions] = useState(initialMenuOptions)
  const [notifications, setNotifications] = useState<Notifications[]>([])
  const { data: session } = useSession();


  // TODO useMemo
  useEffect(() => {
    if (session?.user) {
      // Get custom menu options
      fetch(`/api/v1/users/menu-options`).then((res) => res.json())
        .then((data) => {
          console.log('Menu Options:', data)
          setMenuOptions([...data, ...initialMenuOptions])
        })
        .catch((err: any) => {
          console.log(err)
        })

      // Get notifications
      fetch(`/api/v1/users/notifications`).then((res) => res.json())
        .then(notifications => {
          console.log('Notifications:', notifications)
          setNotifications(notifications)
        })
        .catch((err: any) => {
          console.log(err)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])


  function markNotificationsAsRead(notifications: Notifications[]): void {
    fetch('/api/v1/users/notifications/acknowledge', {
      method: 'POST',
      body: JSON.stringify({notificationIds: notifications.map(n => n.id)})
    }).then(res => res.json()).then(console.log)
    // .then(notifications => setNotifications(notifications))
    .catch(err => console.log(err))
  }

  return (
    <>
      <Disclosure as="nav" className={getEnvColor()}>
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-900 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <Link href="/">
                      <img
                        className="block h-8 w-auto rounded lg:hidden"
                        src="/pill-logo.jpeg"
                        alt="Your Company"
                      />
                    </Link>
                    <Link href="/">
                      <img
                        className="hidden h-8 w-auto rounded lg:block"
                        src="/pill-logo.jpeg"
                        alt="Your Company"
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-2">
                      {navigation.map((item) => !item.current && (
                        <Link
                          key={item.name}
                          href={item.href}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <p
                            className={classNames(
                              'hover:text-epione-green',
                              'px-3 py-2 rounded-md text-2xl font-large',
                              "font-hk font-bold"
                            )}
                          >{item.name}</p>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>

                {process.env.NODE_ENV == 'development' && // TODO remove this
                  <>
                    {session?.user ?
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        {/* Notifications  */}
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button 
                            onClick={() => markNotificationsAsRead(notifications)}
                            className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                            >
                              <span className="sr-only">View notifications</span>
                              <div className="relative">
                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                                {notifications.length > 0 && <div className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full"></div>}
                              </div>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {notifications && notifications.map((item: Notifications) => (
                                <Menu.Item key={item.id}>
                                  <div>
                                    <p className="block px-4 py-2 text-sm text-gray-700">{item.message}</p>
                                  </div>
                                </Menu.Item>)
                              )}
                            </Menu.Items>
                          </Transition>
                        </Menu>



                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">Open user menu</span>
                              <UserAvatar height={8} width={8} />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {menuOptions.map((item) => (
                                <Menu.Item key={item.title}>
                                  {({ active }) => (
                                    <Link
                                      href={item.href}
                                    >
                                      <a className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                      >
                                        {item.title}
                                      </a>
                                    </Link>
                                  )}
                                </Menu.Item>)
                              )}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div> :
                      <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
                        <button
                          className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                          onClick={() => signIn()}
                        >
                          Sign in
                        </button>
                        <Link
                          href="/sign-up"
                        >
                          <a className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700">
                            Sign up
                          </a>
                        </Link>
                      </div>
                    }
                  </>
                }
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => !item.current && (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      'text-epione-green border-2 hover:bg-gray-700 hover:text-white',
                      'block px-3 py-2 rounded-md text-2xl font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    <p className="font-hk font-bold">{item.name}</p>
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}
